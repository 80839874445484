import React from "react"
import styled from "styled-components"
import ImageLeft from "../../images/office-team.jpeg"
import { Link } from "gatsby"

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 6%;
  /* z-index: 2; */
  background: var(--white);

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    padding: 42px 0;
  }

  .img-wrap {
    @media (max-width: 1024px) {
      margin-bottom: 20px;
    }
  }

  .img-wrap img {
    height: 500px;
    width: 450px;
    object-fit: cover;
    object-position: left;
    margin-right: 25px;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.24);
    border: 3px solid #faa62d;
    vertical-align: -webkit-baseline-middle;

    @media (max-width: 1024px) {
      height: 400px;
      width: 500px;
      object-fit: cover;
      margin-right: 0;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`
const ContentWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  @media (max-width: 1023px) {
    align-items: center;
    margin: 0;
    padding: 0 2%;
  }

  h2 {
    font-size: 4em;
    line-height: 1.1;
    letter-spacing: 0.1px;

    @media (max-width: 1024px) {
      font-size: 2.5em;
    }
  }

  p {
    font-size: 1.8em;
    line-height: 1.25;
    letter-spacing: -1px;
    padding-right: 40%;
    margin: 0 0 1em;

    @media (max-width: 1245px) {
      padding: 0;
    }
    @media (max-width: 1024px) {
      padding: 0 4%;
      margin-bottom: 0.5em;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }
`

const CTAButton = styled.button`
  display: inline-block;
  padding: 0.35em;
  width: 250px;
  border: 2px solid var(--cool-black);
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-decoration: uppercase;
  color: var(--cool-black);
  background-color: #00000000;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: var(--warm-orange);
    color: var(--white);
    border: 2px solid var(--cool-black);
    cursor: pointer;
  }
`

export default function ContentArea() {
  return (
    <ContentContainer>
      <div className="img-wrap">
        <img src={ImageLeft} alt="happy employee" />
      </div>
      <ContentWrapper>
        <h2>Our Desire</h2>
        <p>is to reduce your development spend.</p>
        <CTAButton>
          <Link to="/contact">Let's discuss how</Link>
        </CTAButton>
      </ContentWrapper>
    </ContentContainer>
  )
}
