import React from "react"
import Heroelements from "./Heroelements"
import styled from "styled-components"
import BackgroundImg from "../../../images/code-person-background.jpeg"

const ImgDiv = styled.div`
  background: linear-gradient(rgba(26, 26, 26, 0.5), rgba(26, 26, 26, 0.5)),
    url(${BackgroundImg}) !important;
  background-size: auto, cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: 80% 60% !important;
  height: 100vh !important;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10%;

  @media (max-width: 768px) {
    justify-content: center !important;
    background-attachment: scroll !important;
    padding: 0 !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 21;
`

export default function HeroBackground() {
  return (
    <ImgDiv>
      <Wrapper>
        <Heroelements />
      </Wrapper>
    </ImgDiv>
  )
}
