import React from "react"
import Layout from "../components/HeaderFooter/Layout"
import HeroBackground from "../components/Homepage/HeroArea/HeroBackground"
import PartnerNetwork from "../components/Homepage/PartnerNetwork"
import ContentArea from "../components/Homepage/ContentArea"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const BackgroundTextDiv = styled.div`
  z-index: 1;
`
const BackgroundText = styled.h1`
  text-transform: uppercase;
  font-family: "Anton";
  font-size: 7.5em;
  opacity: 0.08;
  text-align: right;
  position: fixed;
  top: 43%;
  left: 30%;
  z-index: -1;

  @media (max-width: 1691px) {
    left: 30%;
    font-size: 6em;
  }
  @media (max-width: 1355px) {
    left: 18%;
    font-size: 5.5em;
  }
  @media (max-width: 1090px) {
    right: 5%;
    left: 5%;
    align-self: center;
    line-height: 1.2;
    font-size: 4em;
  }
`
const HomepageSection2 = styled.div`
  margin: 62px auto;
  max-width: 1280px;
  padding: 0 150px;

  @media (max-width: 1090px) {
    padding: 0 80px;
    right: 5%;
    left: 5%;
    align-self: center;
    line-height: 1.2;
    font-size: 4em;
  }
`
const HomepageS2h2 = styled.div`
  text-align: left;
  padding-right: 30%;

  @media (max-width: 768px) {
    padding-right: 0;
  }

  h2 {
    font-size: 32px;
    letter-spacing: -1.3px;
    line-height: 1.15;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 0.4em;
    }
  }
`
const HomepageS2p = styled.div`
  text-align: right;
  padding-left: 40%;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 40px;
  }

  p {
    font-size: 29px;
    line-height: 1.25;
    letter-spacing: -1px;

    @media (max-width: 1024px) {
      font-size: 26px;
    }
  }
`

const HomepageSection3 = styled.div``
const HomepageSection4 = styled.div``

export default function Home() {
  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Ripplz - Software Development is Evolving</title>
        <meta
          name="description"
          content="Looking for developers to carry out the bulk of your software development? Get in touch with our team today to see how we can help you at 40% cost of traditional agencies"
        />
      </Helmet>
      <section>
        <div>
          <HeroBackground />
        </div>
        <BackgroundTextDiv>
          <BackgroundText>DEVELOPMENT IS EVOLVING</BackgroundText>
        </BackgroundTextDiv>
        <HomepageSection2>
          <HomepageS2h2>
            <h2>
              Our apprentice software developers can carry out the bulk of your
              product development requirements at 40% reduced cost to
              traditional agencies, with the same high standards of delivery.
            </h2>
          </HomepageS2h2>
          <HomepageS2p>
            <p>
              We are bridging the digital skills gap across the UK, hiring
              apprentice software developers to provide unique Gen Z insights to
              the latest development technologies and bringing your products to
              life.
            </p>
          </HomepageS2p>
        </HomepageSection2>
        <HomepageSection3>
          <PartnerNetwork />
        </HomepageSection3>
        <HomepageSection4>
          <ContentArea />
        </HomepageSection4>
      </section>
    </Layout>
  )
}
