import React from "react"
import styled from "styled-components"
import CodeNationLogo from "../../images/code-nation-logo.png"
import JobxaiLogo from "../../images/jobxai-logo.png"
import LogicalLogo from "../../images/logical-resources-logo.png"

const PartnerNetworkContainer = styled.div`
  display: flex;
  justify-content: center;
  .partner-box {
    width: 100%;
    background: var(--cool-black);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.24);

    @media (max-width: 1023px) {
      box-shadow: none;
    }
  }
`
const PartnerNetworkH2 = styled.h2`
  color: var(--white);
  font-family: "montserrat";
  font-size: 2.5em;
  font-weight: 500;
  margin: 40px 0;
  padding: 0 20px;

  @media (max-width: 1023px) {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 30px;
  }
`

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  width: 100%;

  @media (max-width: 1023px) {
    flex-flow: column wrap;
  }

  div {
    padding: 10px 30px;
  }

  img {
    width: 220px;
    height: auto;
  }
  .code-nation {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;

    @media (max-width: 1023px) {
      flex-flow: row wrap;
    }
  }

  .jobxai {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: space-evenly;

    @media (max-width: 1023px) {
      filter: none;
    }
  }
  .logical {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: space-evenly;

    @media (max-width: 1023px) {
      filter: none;
    }
  }
`

export default function PartnerNetwork() {
  return (
    <PartnerNetworkContainer>
      <div className="partner-box">
        <PartnerNetworkH2>Our Partner Network</PartnerNetworkH2>
        <BrandContainer>
          <div className="code-nation">
            <a
              href="https://wearecodenation.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={CodeNationLogo} alt="code nation logo" />
            </a>
          </div>
          <div className="jobxai">
            <a href="https://www.jobxai.com/" target="_blank" rel="noreferrer">
              <img src={JobxaiLogo} alt="jobxai logo" />
            </a>
          </div>
          <div className="logical">
            <a
              href="https://www.logicalresources.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LogicalLogo} alt="logical logo" />
            </a>
          </div>
        </BrandContainer>
      </div>
    </PartnerNetworkContainer>
  )
}
