import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Typing from "react-typing-animation"

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }
`
const TextElement = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
`

const HeroH1 = styled.h1`
  font-size: 4.8em;
  font-family: "montserrat";
  color: var(--white);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 2.8em;
  }
`
const HeroTextDiv = styled.div`
  .efaGep {
    color: white;
  }
`

const HeroH2 = styled.h2`
  font-family: "open sans", sans-serif;
  font-size: 1.4em;
  letter-spacing: normal;
  color: var(--white);
  font-weight: 400;
  width: 400px;

  @media (max-width: 768px) {
    width: 350px;
    text-align: center;
    font-size: 1.4em;
  }
`
const CTAButton = styled.button`
  display: inline-block;
  padding: 0.35em;
  width: 200px;
  border: 2px solid #ffffff;
  margin: 20px 0.3em 0.3em 0;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-decoration: uppercase;
  color: var(--white);
  background-color: #00000000;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: var(--warm-orange);
    cursor: pointer;
  }
`

const AnimatedTypingComponenet = () => (
  <HeroTextDiv>
    <span style={{ color: "white" }}>
      Developing
      <Typing loop={true} speed={75} startDelay={600} className="typewriter">
        <span style={{ color: "white" }}>
          Gen Z Talent
          <Typing.Backspace count={13} delay={2500} />
          Your Dev Team
          <Typing.Backspace count={19} delay={2500} />
          The Future
          <Typing.Backspace count={10} delay={6000} />
        </span>
      </Typing>
    </span>
  </HeroTextDiv>
)

export default function Heroelements() {
  return (
    <Wrapper>
      <TextElement>
        <HeroH1 style={{ color: "white" }}>
          <AnimatedTypingComponenet />
        </HeroH1>
        <HeroH2>
          The software development agency built upon the back of qualified Gen Z
          apprentices.
        </HeroH2>
        <Link to="/what-we-do">
          <CTAButton>Find out more</CTAButton>
        </Link>
      </TextElement>
    </Wrapper>
  )
}
